import LadiesType from './LadiesType.vue'
import LadiesTypeCreate from './Create.vue'

export default [
    {
        path: '/ladies_type',
        name: 'LadiesType',
        component: LadiesType
    },
    {
        path: '/ladies_type/create',
        name: 'LadiesTypeCreate',
        component: LadiesTypeCreate
    },
    {
        path: '/ladies_type/edit/:id',
        name: 'LadiesTypeEdit',
        component: LadiesTypeCreate
    },
]
